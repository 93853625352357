<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Redeemed</h2>
    </template>
    <template #action-bar></template>
    <template #page-content>
      <b-table
        :data="dataRedeemed.data ? dataRedeemed.data : []"
        :loading="isLoading"
        paginated
        backend-pagination
        :total="dataRedeemed.meta.total"
        :per-page="params.perPage"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        backend-sorting
        @sort="onSort"
      >
        <b-table-column field="no" label="No" v-slot="props" centered>
          {{ props.index + 1 }}
        </b-table-column>

        <b-table-column
          field="name"
          label="Voucher Name"
          v-slot="props"
          centered
        >
          {{ props.row.voucher.name }}
        </b-table-column>

        <b-table-column
          field="code"
          label="Voucher Code"
          v-slot="props"
          centered
        >
          {{ props.row.voucher.code }}
        </b-table-column>

        <b-table-column field="company" label="Company" v-slot="props" centered>
          {{ props.row.company.name }}
        </b-table-column>

        <template #empty>
          <div class="has-text-centered">
            No data yet, please contact us if you have any problems
            (hello@getrise.id)
          </div>
        </template>
      </b-table>
    </template>
  </r-page>
</template>

<script>
export default {
  data() {
    return {
      dataRedeemed: {
        data: [],
        meta: {},
      },
      params: {
        page: 1,
        perPage: 10,
        orderBy: 'created_at',
        orderDirection: 'desc',
      },
      isLoading: false,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      this.$store
        .dispatch('voucher/indexVoucherRedeemed', this.params)
        .then((response) => {
          this.dataRedeemed.data = response.data.data
          this.dataRedeemed.meta = response.data.meta
          this.isLoading = false
        })
    },
    onPageChange(page) {
      this.params.page = page
      this.fetchData()
    },
    onSort(field, order) {
      this.params.orderBy = field
      this.params.orderDirection = order
      this.fetchData()
    },
  },
}
</script>
